import { Capacitor } from "@capacitor/core";
import { PhoneList } from "app/chat/base/phone-list";
import { LocalizedLink } from "app/locale";
import classNames from "classnames";
import * as React from "react";
import metaConfig from "../../../meta.json";
import { CapacitorLiveUpdate } from "../../modules/capacitor/live-update";
import * as Client from "../../modules/client";
import { getCurrentConfig } from "../config";
import { Envelop } from "./icons";
import * as Icon from "./icons";

type NavLinkType = {
    path: string;
    label: string;
    icon: React.ReactNode;
};

const linksPrimary: Array<NavLinkType> = [
    {
        path: "about",
        label: "About KoboGo",
        icon: <Icon.NavArrow />,
    },
    {
        path: "contacts",
        label: "Contacts",
        icon: <Icon.NavArrow />,
    },
    {
        path: "faq",
        label: "Help",
        icon: <Icon.NavArrow />,
    },
    {
        path: "docs",
        label: "Service documentation",
        icon: <Icon.NavArrow />,
    },
];

const linksSecondary: Array<NavLinkType> = [];

const navLink = (links: Array<NavLinkType>) => {
    return (
        <>
            {links.map(({ icon, path, label }) => (
                <li key={label}>
                    <LocalizedLink to={path}>
                        <span>{label}</span>
                        {icon}
                    </LocalizedLink>
                </li>
            ))}
        </>
    );
};

export const LinkList = () => {
    const clientState = Client.useStateContext();
    const _linksSecondary = [...linksSecondary];
    const _linksPrimary = [...linksPrimary];
    const [buildString, setBuildString] = React.useState("");

    React.useEffect(() => {
        if (!Capacitor.isNativePlatform()) {
            setBuildString("Build: development-web");
            return;
        }
        CapacitorLiveUpdate.getUsedVersion().then((usedLiveUpdateVersion) => {
            let releaseName = `${metaConfig.channel}-${usedLiveUpdateVersion || metaConfig.version}`;

            if (usedLiveUpdateVersion) {
                releaseName += "-live";
            }

            setBuildString(`Build: ${releaseName}`);
        });
    }, []);

    if (clientState.user) {
        _linksSecondary.push({
            path: "logout",
            label: "Leave account",
            icon: <Icon.Signout />,
        });
    }

    if (getCurrentConfig().environment !== "production") {
        _linksPrimary.push({
            path: "debug-info",
            label: "DEBUG INFO",
            icon: <Icon.NavArrow />,
        });
    }

    return (
        <nav className="nav">
            <ul
                className={classNames(
                    "nav__primary",
                    _linksSecondary.length
                        ? ""
                        : "nav__primary_no-bottom-border",
                )}
            >
                {navLink(_linksPrimary)}
            </ul>
            <ul className="nav__secondary">{navLink(_linksSecondary)}</ul>
            <div className="phones">
                <PhoneList />
            </div>

            <a
                href="mailto:info@kobogo.ng"
                className="nav__email">
                <Envelop />
                &nbsp; info@kobogo.ng
            </a>
            <div className={"nav__build"}>
                { buildString }
            </div>
        </nav>
    );
};

LinkList.displayName = "Navigation.LinkList";
