import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { PluginListenerHandle } from "@capacitor/core/types/definitions";
import React from "react";
import { CapacitorOnBrowserOpenError } from "../../capacitor/browser";

const waitForUrlEventTimeout: number = 3000;

export interface OpenInCapacitorBrowserProps {
    url: string;
    onAppUrlOpen: (url: string) => void;
    onBrowserFinished: () => void;
}
export const OpenInCapacitorBrowser: React.FC<React.PropsWithChildren<OpenInCapacitorBrowserProps>> = (
    { url, onAppUrlOpen, onBrowserFinished },
): null => {
    let urlListener: PluginListenerHandle;
    let browserCloseListener: PluginListenerHandle;
    let timeoutId: number | undefined;
    let isCloseHandled: boolean = false;

    React.useEffect(() => {
        if ((!onAppUrlOpen && !onBrowserFinished) || !url) {
            return;
        }

        Browser.open({ url, windowName: "_blank" }).then((): void => {
            /** Зачем все так сложно?*/
            /** Потому что юзер может закрыть окно браузера крестиком, а можем закрыть его мы через deepLink */
            /** В случае если мы закрываем его через deepLink событие browserFinished так же вызывается вместе с appUrlOpen*/
            /** Чтобы этого избежать мы пишем этот костыль. Если вызвался appUrlOpen */
            /** то из-за isCloseHandled = true; логика в browserFinished уже не отработает */

            App.addListener("appUrlOpen", (event: URLOpenListenerEvent): void => {
                isCloseHandled = true;

                onAppUrlOpen(event.url);
            }).then(l => (urlListener = l));

            Browser.addListener("browserFinished", () => {
                timeoutId = window.setTimeout(() => {
                    timeoutId = undefined;

                    /** Если вызван обработчик appUrlOpen, то код ниже нам больше не нужен*/
                    if (isCloseHandled) {
                        return;
                    }

                    onBrowserFinished();
                }, waitForUrlEventTimeout);
            }).then(l => (browserCloseListener = l));
        })
            .catch(msg => CapacitorOnBrowserOpenError({ url, msg }));

        return () => {
            browserCloseListener && browserCloseListener.remove();
            urlListener && urlListener.remove();
            timeoutId && clearTimeout(timeoutId);
        };
    }, []);

    return null;
};
