import { Capacitor } from "@capacitor/core";
import { AxiosInstance, AxiosResponse } from "axios";
import { DeepLinkCloseBrowserDashboardPayment } from "../../../app/app-url-listener/AppUrlListener";

export type Order = {
    action: string;
    data: Array<[string, string]>;
};
export type OrderOptions = { goodUrl?: string; } & (
    | { type: "card"; }
    | { type: "prolongation"; card?: string; }
    | { type: "repayment"; card?: string; amount: number; }
    );

export type Service = "paystack" | "payarena" | "test";

export type CardState = "verified" | "verifying" | "active";

export type Card = {
    uuid: string;
    mask: string;
    service: Service;
    state: CardState;
    created_at: string;
    bank: string;
};
export type CardVerifyResponse = {
    message: string | undefined;
    status: number | 201 | 423 | 424;
};

// export type OrderRequest = (options: OrderOptions) => Promise<Order>;
// export type CardListRequest = () => Promise<Array<Card>>;
// export type CardVerifyRequest = (
//     uuid: string,
//     amount: number
// ) => Promise<string | undefined>;
// export type Api = {
//     order: OrderRequest;
//     cards: CardListRequest;
//     verify: CardVerifyRequest;
// };

export type TransactionBaseDetails = {
    status: "Pending" | "Cancelled" | "Declined" | "Initiated" | "Timeout";
    amount: string;
    orderId: string;
};

export type TransactionFullDetails = {
    amount: string;
    reference: string;
    status: "Approved";
    details: {
        scheme: string;
        date: string;
    };
};

export type TransactionDetails = TransactionBaseDetails | TransactionFullDetails;

export const Api = (endpoint: AxiosInstance) => {
    return {
        order: async (options: OrderOptions): Promise<Order> => {
            const defaultGoodOrder = Capacitor.isNativePlatform() ? `${DeepLinkCloseBrowserDashboardPayment}/passed` : window.location.origin + "/dashboard/payment/passed";
            const Order: { [k: string]: number | string; } = {
                goodUrl: options.goodUrl ?? defaultGoodOrder,
            };
            switch (options.type) {
                case "repayment":
                    Order.amount = options.amount;
                case "prolongation":
                    if ("string" === typeof options.card) {
                        Order.card = options.card;
                    }
                    break;
            }
            const route = "/v3/payment/auto/order/";
            return endpoint
                .post<{
                action: string;
                data: { [k: string]: string; };
            }>(route + options.type, { Order })
                .then(({ data: { action, data } }) => ({
                    action,
                    data: Object.entries(data) as Array<[string, string]>,
                }));
        },
        cards: async (): Promise<Array<Card>> => {
            return endpoint
                .get<{
                [k in Card["state"]]: Array<Card> | Card;
            }>("/v3/payment/cards")
                .then(({ data }): Array<Card> => {
                    return Object.entries(data)
                        .map(([state, cards]): [Card["state"], Array<Card>] => {
                            return [
                                state as Card["state"],
                                Array.isArray(cards) ? cards : [cards],
                            ];
                        })
                        .reduce<Array<Card>>((arr, [state, cards]) => {
                            cards.forEach(card =>
                                arr.push({ ...card, state }),
                            );
                            return arr;
                        }, []);
                });
        },
        verifying: async (): Promise<Array<Card>> => {
            return endpoint
                .get("/v3/payment/cards/verifying")
                .then(({ data }): Array<Card> => {
                    return data.list;
                });
        },
        active: async (): Promise<Card> => {
            return endpoint
                .get("/v3/payment/card/active")
                .then(({ data }): Card => {
                    return data.card;
                });
        },
        activate: async (uuid: string): Promise<any> => {
            return endpoint.patch("/v3/payment/card", {
                Active: { uuid },
            });
        },
        verify: async (
            uuid: string,
            amount: number,
        ): Promise<CardVerifyResponse> => {
            return endpoint
                .patch<{ message?: string; }>(
                    "/v3/payment/verification",
                    {
                        Confirm: { amount },
                    },
                    {
                        params: { uuid },
                        validateStatus: status =>
                            [423, 424, 201].includes(status),
                    },
                )
                .then(({ data, status }) => ({
                    message: data.message,
                    status,
                }));
        },
        delete: async (uuid: string): Promise<AxiosResponse> => {
            return endpoint.delete("/v3/payment/verification", {
                params: { uuid },
            });
        },
        details: async (id: string): Promise<TransactionDetails> => {
            const response = await endpoint.get<TransactionDetails, AxiosResponse<TransactionDetails>>(
                "/v3/payment/auto/order/details",
                { params: { id } },
            );
            return response.data;
        },
    } as const;
};
