import { Capacitor } from "@capacitor/core";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import * as Sentry from "@sentry/capacitor";
import { FlexibleStorage } from "flexible-storage";
import { History } from "history";
import * as React from "react";
import { AdjustSdk } from "./adjust-sdk";
import { initAppsFlyer } from "./apps-flyer";
import { initCapacitorBrowser } from "./browser";
import { CapacitorDeviceInfo, grabAllDeviceInfo, GrabAllDeviceInfoType } from "./device";
// import {initKochavaSdk} from "./kochava";
import { CapacitorLiveUpdate, initLiveUpdate } from "./live-update";
import { initPushNotification } from "./push-notifications";
import { CapacitorStoreAction } from "./store/reducer";
import { initUpdateApp } from "./update";


export const CapacitorFlexibleStorage: FlexibleStorage = new FlexibleStorage(window.localStorage, "capacitor:");

declare global {
    interface Window {
        installReferrer?: {
            getReferrer: (
                success: (installReferrerObj: installReferrerObj) => void,
                error: (error: string) => void
            ) => void;
        };
    }
}

export interface installReferrerObj {
    clickTs: string;
    installTs: string;
    isInstantExperienceLaunched: string;
    responseCode: installReferrerResponseCode;
    utm_medium: string;
    utm_source: string;
    originalReferrer: string;
}
export enum installReferrerResponseCode {
    OK = "0",
    FEATURE_NOT_SUPPORTED = "1",
    SERVICE_UNAVAILABLE = "2",
}

function initClasses() {
    document.body.classList.add("capacitor");
    document.body.classList.add(`capacitor__${Capacitor.getPlatform()}`);
    document.documentElement.classList.add("capacitor");
    document.documentElement.classList.add(`capacitor__${Capacitor.getPlatform()}`);
}

async function initFirebaseAnalytics() {
    await FirebaseAnalytics.setEnabled({
        enabled: true,
    });
}

async function initFacebookAnalytics() {
    try {
        const value = await FacebookLogin.getDeferredDeepLink();
        value.uri && localStorage.setItem("capacitor:facebook-deferred-deep-link", value.uri);
        return value.uri;
    } catch (error) {
        return null;
    }
}

function initHelper(initFunction: () => Promise<unknown> | void) {
    try {
        const result = initFunction();
        if (result instanceof Promise) {
            return result.catch((e) => {
                console.warn(e);
                Sentry.captureException(e);
            });
        }
    } catch (e) {
        console.warn(e);
        Sentry.captureException(e);
    }
}

interface PluginResults {
    liveUpdateVersion: string | undefined | null;
    adjustSdk: unknown;
    firebaseAnalytics: string | null;
    deferredFacebookDeepLink: string | null;
    classesInitialized: void | null;
    capacitorBrowser: unknown;
    pushNotificationToken: string | undefined | null;
    deviceInfo: GrabAllDeviceInfoType | null;
    appsFlyer: unknown;
    updateApp: unknown;
}

const DefaultResults: PluginResults = {
    liveUpdateVersion: null,
    adjustSdk: null,
    firebaseAnalytics: null,
    deferredFacebookDeepLink: null,
    classesInitialized: null,
    capacitorBrowser: null,
    pushNotificationToken: null,
    deviceInfo: null,
    appsFlyer: null,
    updateApp: null,
};

export async function initCapacitorPlugins(
    history: History,
    dispatch: React.Dispatch<CapacitorStoreAction>,
): Promise<PluginResults> {
    const results: PluginResults = DefaultResults;

    if (!Capacitor.isNativePlatform()) {
        return results;
    }

    results.deviceInfo = (await initHelper(() =>
        grabAllDeviceInfo().then((deviceInfo) => {
            dispatch({ type: "SET_DEVICE_INFO", payload: deviceInfo });
            return deviceInfo;
        }),
    )) as GrabAllDeviceInfoType;

    results.liveUpdateVersion = await initHelper(() =>
        initLiveUpdate().then(async () => {
            const version = (await CapacitorLiveUpdate.getUsedVersion()) || undefined;
            dispatch({ type: "SET_LIVE_UPDATE_VERSION", payload: version });
            return version;
        }),
    ) as string | undefined | null;

    results.adjustSdk = await initHelper(AdjustSdk.init);

    results.firebaseAnalytics = await initHelper(initFirebaseAnalytics) as string | null;

    results.deferredFacebookDeepLink = await initHelper(initFacebookAnalytics) as string | null;

    results.classesInitialized = await initHelper(initClasses) as null;

    results.capacitorBrowser = await initHelper(initCapacitorBrowser);

    results.pushNotificationToken = await initHelper(async () =>
        initPushNotification(history)
            .then((token) => {
                dispatch({ type: "SET_PUSH_TOKEN", payload: token });
                return token;
            })
            .catch(() => {
                dispatch({ type: "SET_PUSH_TOKEN", payload: undefined });
                return undefined;
            }),
    ) as string | undefined | null;

    results.appsFlyer = await initHelper(initAppsFlyer);

    results.updateApp = await initHelper(initUpdateApp);

    return results;
}
