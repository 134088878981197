import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { LocalizedRedirect } from "app/locale";
import * as Client from "modules/client";
import { userStatuses } from "modules/client/api";
import { BotOverlay } from "modules/components/bot/overlay";
import * as Main from "modules/components/main";
import { useBodyClassName } from "modules/hooks/use-body-class-name";
import { useHead } from "modules/hooks/use-head";
import { useTheme } from "modules/hooks/use-theme";
import * as React from "react";
import { Header } from "./header";
import Provider from "./provider";
import { Section } from "./section";
import "modules/halloween/dark-theme.scss";
import "./styles.scss";

export const Layout = Client.withState<
    Pick<Client.State, "type">,
    { userStatus: Client.Api.UserStatus | undefined; }
>(
    ({ type, userStatus }) => {
        React.useEffect(() => {
            if (type === "guest") {
                document.body.classList.add("show-footer");
            }
            return () => document.body.classList.remove("show-footer");
        }, []);

        useTheme("chat");
        // TODO: change meta
        useHead("KoboGo", "KoboGo");
        useBodyClassName("dark-theme");

        if (!localStorage.getItem("custom_firstopen_sent") && Capacitor.isNativePlatform()) {
            FirebaseAnalytics.logEvent({ name: "custom_firstopen" });
            localStorage.setItem("custom_firstopen_sent", "true");
        }

        if (type === "user" && !userStatuses.includes(userStatus!)) {
            return <LocalizedRedirect to={"/upgrade-required"} />;
        }

        return (
            <>
                <Header />
                <BotOverlay />
                <Main.Layout>
                    <Section>
                        <div className="chat-stream">
                            <div className="chat-list">
                                <Provider />
                            </div>
                        </div>
                    </Section>
                </Main.Layout>
            </>
        );
    },
    (state) => {
        return {
            type: state.type,
            userStatus: state.type === "user" ? state.user.status : undefined,
        };
    },
);

Layout.displayName = "Chat.Layout";

export default Layout;
