import { Device } from "@capacitor/device";
import { BatteryInfo, DeviceInfo } from "@capacitor/device/dist/esm/definitions";
import * as Sentry from "@sentry/capacitor";
import { AxiosRequestConfig } from "axios";
import { CapacitorInstallerSource } from "capacitor-installer-source";
import dayjs from "dayjs";
import { blobHash } from "../utils/blobHash";

const createErrorHandler =
    (errorSource: string) =>
        (error: Error): Partial<CapacitorDeviceInfo> => {
            Sentry.captureMessage(`DeviceInfo.${errorSource}() - failed`, {
                level: "error",
                extra: { error },
            });
            return {};
        };

export interface CapacitorDeviceInfo {
    deviceId: string;
    deviceInfo: DeviceInfo;
    batteryInfo: BatteryInfo;
    languageCode: string;
    languageTag: string;
    installerSourceBundleId: string;
}

export type GrabAllDeviceInfoType = Partial<Omit<CapacitorDeviceInfo, "deviceId">> & Required<Pick<CapacitorDeviceInfo, "deviceId">>;
export async function grabAllDeviceInfo(): Promise<GrabAllDeviceInfoType> {
    const promises: Promise<Partial<CapacitorDeviceInfo>>[] = [
        Device.getId()
            .then(val => ({ deviceId: val.identifier }))
            .catch(createErrorHandler("getId")),
        Device.getInfo()
            .then(val => ({ deviceInfo: val }))
            .catch(createErrorHandler("getInfo")),
        Device.getBatteryInfo()
            .then(val => ({ batteryInfo: val }))
            .catch(createErrorHandler("getBatteryInfo")),
        Device.getLanguageCode()
            .then(({ value: languageCode }) => ({ languageCode }))
            .catch(createErrorHandler("getLanguageCode")),
        Device.getLanguageTag()
            .then(({ value: languageTag }) => ({ languageTag }))
            .catch(createErrorHandler("getLanguageTag")),
        CapacitorInstallerSource.getSourceInfo()
            .then(({ bundleId: installerSourceBundleId }) => ({ installerSourceBundleId }))
            .catch(createErrorHandler("getSourceInfo")),
    ];
    const deviceInfo: Partial<GrabAllDeviceInfoType> = (
        await Promise.all(promises)
    ).reduce(
        (prev, curr) => Object.assign(prev, curr), {},
    );

    if (!deviceInfo.deviceId) {
        const blobDeviceInfo: Blob = new Blob([JSON.stringify(deviceInfo)], { type: "text/plain" });
        deviceInfo.deviceId = `R:${dayjs(new Date()).format("YYYYMMDD.Hs")}:${await blobHash(blobDeviceInfo)}`;
    }

    // TODO оставить только в store
    localStorage.setItem("capacitor:device-info", JSON.stringify(deviceInfo));
    localStorage.setItem("capacitor:device-id", deviceInfo.deviceId);

    return deviceInfo as GrabAllDeviceInfoType;
}

export async function deviceIdRequestInterceptor(req: AxiosRequestConfig<any>): Promise<AxiosRequestConfig<any>> {
    let deviceId: string;

    // TODO поменять на запрос из capacitorStore
    try {
        deviceId = JSON.parse(localStorage.getItem("capacitor:device-id") || "null") || (await grabAllDeviceInfo()).deviceId;
    } catch (e) {
        deviceId = (await grabAllDeviceInfo()).deviceId;
    }

    req.headers = { ...req.headers, "x-bobra-device-id": deviceId };

    return req;
}
